<template>
  <el-dropdown v-if="isLogged" trigger="click" class="pointer">
                <span class="el-dropdown-link">
                  {{ userName }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item icon="el-icon-s-custom" @click="$router.push('/dashboard/utente/'+appUser.id)">
          {{$t('loginDropdown.dropdownItems.profile')}}
        </el-dropdown-item>
        <el-dropdown-item icon="el-icon-circle-close" @click="goTo('logout')">
          {{$t('loginDropdown.dropdownItems.logout')}}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  <div v-else>
    <span class="login-link-item pointer" @click="goTo('login')">
      {{$t('loginDropdown.links.login')}}
    </span>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UserMixin from "../mixins/UserMixin";

export default {
  name: "LoginDropdown",
  mixins: [UserMixin],
  data() {
    return {
      userName: "",
    };
  },
  computed: {
    ...mapGetters(["isLogged", "appUser", "currentUser"])
  },
  methods: {
    ...mapActions(["fetchUser"]),
    goTo(name) {
      this.$router.push({name})
    },
  },
  async created() {
    if (this.isLogged){
      await this.fetchUser(this.appUser.id);
      this.userName = this.getFullName(this.currentUser);
    }
  },
}
</script>

<style scoped>

  .login-link-item{
    margin: 0 10px;
  }

</style>
