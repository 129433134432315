<template>
  <el-footer>
    <span style="color: darkgrey">{{$t('sidebar.footer') + getVersion()}} </span>
    <div class="links">
      <span class="login-link-item pointer" @click="goTo('contact-us')">
        {{$t('footer.links.contact-us')}}
      </span>
      <span class="login-link-item pointer" @click="goTo('cookie-policy')">
        {{$t('footer.links.cookie-policy')}}
      </span>
      <span class="login-link-item pointer" @click="goTo('privacy-policy')">
        {{$t('footer.links.privacy-policy')}}
      </span>
    </div>
  </el-footer>
</template>

<script>

export default {
  name: "Footer",
  methods: {
    goTo(name) {
      this.$router.push({name})
    },
    getVersion() {
      return process.env.PACKAGE_VERSION || '0'
    },
  },
}
</script>

<style scoped>

  .el-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .login-link-item{
    padding: 0 15px;
    color: #409eff;
  }

</style>
