const typeLabels = {
    user: "Utente Base",
    admin: "Fornitore di Servizi"
}


const UserMixin = {
    methods: {
        getRoleInitials: (role) => {
            switch (role) {
                case "ROLE_ADMIN":
                    return "SU";
                case "ROLE_MANAGER":
                    return "M";
                default:
                    return "U"
            }
        },
        getMainRole: (roles) => {
            const rList = ["ROLE_ADMIN", "ROLE_MANAGER", "ROLE_CONDO"];
            for(let role of rList){
                if(roles.indexOf(role) >= 0)
                    return role;
            }
            return 'ROLE_USER';
        },
        stringifyMainRole: (roles) => {
            //console.log(roles)
            const main = this.getMainRole(roles);
            switch (main){
                case "ROLE_ADMIN":
                    return "Amministratore di Sistema";
                case "ROLE_MANAGER":
                    return "Manager";
                case "ROLE_USER":
                    return "Utente base";
            }

            return "";
        },
        getFullName: (user) => {
            // if(user){
            //     if(user.businessName){
            //         return user.businessName
            //     }
            //     return [(user.name||""), (user.surname||"")].join(" ");
            // }
            // return ""
            if (user){
                let fullname = [(user.businessName||""), (user.surname||""), (user.name||"")].join(" ")
                if (fullname === "  "){
                    return "[nominativo assente]";
                } else {
                    return fullname;
                }
            }
            return "";

        },
        getTypeLabel: type => {
            return typeLabels[type];
        },
        getTypeSelectFields : () => {
            return [
                {
                    label: "Utente Base",
                    value: "user"
                },
                {
                    label: "Fornitore di Servizi",
                    value: "admin"
                }
            ];
        }
    }
}

export default UserMixin;
